import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {Platform} from '@angular/cdk/platform';
import {
  eCaseAnimations,
  ECaseConfigService,
  EcaseHttpService, ECaseSnackBarService, ECaseStateMatcher, ECaseUtils,
  LanguageService,
  LoginAuthenticationService, ParametersService, TitleService
} from "synto-common";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
  animations: eCaseAnimations
})
export class TwoFactorAuthenticationComponent implements OnInit {
  twoFactorAuthenticationForm: FormGroup;
  verifyCodeForm: FormGroup;
  twoFactorAuthenticationFormErrors: any;
  logoSrc = '';
  baseUrl = '';
  selectedLanguage: any;
  languages: any[];
  matcher = new ECaseStateMatcher();
  showLanguageSwitcher: boolean;
  isDropShadowEnabled = false;
  isAlternativeEmailAvailable = false;
  isPhoneNumberAvailable = false;
  isCodeSent = false;
  yesNoList = [];
  mfaModes = [];
  selectedMfaMode = '';
  isMobile = false;


  constructor(private eCaseConfig: ECaseConfigService, private formBuilder: FormBuilder, public translate: TranslateService,
              private eCaseHttpService: EcaseHttpService, private loginAuthenticationService: LoginAuthenticationService,
              private eCaseSnackBarService: ECaseSnackBarService, private titleService: TitleService, private cookieService: CookieService,
              public router: Router, private languageService: LanguageService, private parametersService: ParametersService,
              public platform: Platform, @Inject(DOCUMENT) private document: Document) {
    this.twoFactorAuthenticationFormErrors = {
      email: {}
    };
  }

  setLanguage(lang): void {
    this.selectedLanguage = lang;
    this.translate.use(lang.id);
    this.translate.setDefaultLang(lang.id);
    this.document.documentElement.lang = lang.id;
    this.titleService.setTitleWithSeparator(['ecase.common.2fa', 'ecase.common.defaultBrowserTabTitle'], ' | ');
    this.updateLogoSrc(lang.id);
  }

  updateLogoSrc(lang): void {
    if (this.parametersService.parameter['/core/ui/organisation_logo/multilingual_logo/enabled'] === 'true') {
      const splitSrc = this.logoSrc.split('_');
      this.logoSrc = splitSrc[0] + '_' + lang + '.' + splitSrc[1].split('.')[1];
    }
  }

  ngOnInit(): void {
    if (!this.loginAuthenticationService.firstClickableFeature) {
      this.exitAndLogOut();
    } else {
      this.isMobile = (this.platform.ANDROID || this.platform.IOS);
      this.titleService.setTitleWithSeparator(['ecase.common.2fa', 'ecase.common.defaultBrowserTabTitle'], ' | ');
      this.logoSrc = this.parametersService.parameter['/core/ui/organisation_logo/url'];
      this.mfaModes = JSON.parse(this.parametersService.parameter['/core/security/activated_mfa_modes']);
      this.showLanguageSwitcher = this.parametersService.parameter['/core/show_language_switcher/enabled'] === 'true';
      this.isDropShadowEnabled = this.parametersService.parameter['/core/ui/hosting_organisation_logo_drop_shadow/enabled'] === 'true';
      this.eCaseHttpService.get('/api/getOrganisationLogoDetailsAndBaseUrl').subscribe((response: any) => {
        if (response.status) {
          this.baseUrl = response.baseUrl;
          this.loginAuthenticationService.baseUrl = this.baseUrl.includes('localhost') ? 'http://' + this.baseUrl : 'https://' + this.baseUrl;
        } else {
          this.eCaseSnackBarService.show('failure');
        }
      });
      this.twoFactorAuthenticationForm = this.formBuilder.group(
        {
          authenticationMode: ['', [Validators.required]],
          alternativeEmail: [{value: '', disabled: true}],
          originalFormat: [{value: '', disabled: true}],
          e164Format: [{value: '', disabled: true}]
        });
      this.verifyCodeForm = this.formBuilder.group(
        {
          securityCode: ['', [Validators.required]],
          isDeviceTrusted: ['', [Validators.required]]
        });
      const obj = {
        taxonomyId: '1'
      };
      this.eCaseHttpService.post('/api/getAllTermsWithTaxonomyId', obj).subscribe((response: any) => {
        if (response.status) {
          this.yesNoList = response.data.allTerms;
        }
      });
      this.eCaseHttpService.get('/api/getTwoFactorAuthenticationDetails').subscribe((response: any) => {
        if (response.status) {
          if (response.alternativeEmails.length > 0) {
            this.twoFactorAuthenticationForm.get('alternativeEmail').patchValue(response.alternativeEmails[0]);
            this.isAlternativeEmailAvailable = true;
          }
          if (response.phoneNumbers.length > 0) {
            this.twoFactorAuthenticationForm.get('originalFormat').patchValue(response.phoneNumbers[0].originalFormat);
            this.twoFactorAuthenticationForm.get('e164Format').patchValue(response.phoneNumbers[0].e164Format);
            this.isPhoneNumberAvailable = true;
          }
          if (this.mfaModes.length === 1) {
            switch (this.mfaModes[0]) {
              case 'username' :
                this.sendSecurityCode('0');
                break;
              case 'altEmail' :
                this.sendSecurityCode('1');
                break;
              case 'sms' :
                this.sendSecurityCode('2');
                break;
            }
          }
        } else {
          this.eCaseSnackBarService.show('failure', response.errorMessage);
        }
      });
      this.twoFactorAuthenticationForm.valueChanges.subscribe(() => {
        this.onTwoFactorAuthenticationFormValuesChanged();
      });
      this.languages = this.languageService.languageList;
      if (this.languages && this.languages.length > 0) {
        this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
      } else {
        this.languageService.getAllLanguages().subscribe((data) => {
          this.languages = this.languageService.createLanguage(data);
          this.languageService.languageList = this.languages;
          console.log(this.languages);
          this.selectedLanguage = this.languages.filter(item => item.id === this.translate.getDefaultLang())[0];
        });
      }
      this.updateLogoSrc(this.translate.getDefaultLang());
    }
  }


  onTwoFactorAuthenticationFormValuesChanged(): void {
    for (const field in this.twoFactorAuthenticationFormErrors) {
      if (!this.twoFactorAuthenticationFormErrors.hasOwnProperty(field)) {
        continue;
      }
      this.twoFactorAuthenticationFormErrors[field] = {};
      const control = this.twoFactorAuthenticationForm.get(field);
      if (control && control.dirty && !control.valid) {
        this.twoFactorAuthenticationFormErrors[field] = control.errors;
      }
    }
  }

  sendSecurityCode(mode: string): void {
    this.selectedMfaMode = mode;
    this.eCaseHttpService.post('/api/sendSecurityCode', {'authenticationMode': mode}).subscribe((_response: any) => {
      if (_response.status) {
        this.isCodeSent = true;
        this.eCaseSnackBarService.show('success', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.authenticationcodesent'));
      } else {
        this.eCaseSnackBarService.show('failure', _response.errorMessage);
      }
    });
  }

  verifyCodeAndSignIn(): void {
    const code = this.verifyCodeForm.controls['securityCode'].value;
    const isTrusted = this.verifyCodeForm.controls['isDeviceTrusted'].value;
    this.eCaseHttpService.post('/api/verifyCodeAndSignIn', {
      'securityCode': code,
      'isDeviceTrusted': isTrusted
    }).subscribe((_response: any) => {
      if (_response.status) {
        if (isTrusted === 1 && _response.cookie) {
          this.cookieService.set(_response.cookie.name, _response.cookie.value, 365, undefined, undefined, true, 'Lax');
        }
        if (this.loginAuthenticationService.isProfileCompleted !== 'Y' && this.loginAuthenticationService.firstClickableFeature.parentRootId === 3) {
          this.router.navigate(['/dashboard/profile/updateContact']).then(() => {
          });
        } else {
          if (this.loginAuthenticationService.isUserRedirectedForLogIn
            && this.loginAuthenticationService.oldRedirectedUrl) {
            if (ECaseUtils.isUserHasAccessToUrl(this.loginAuthenticationService.oldRedirectedUrl, this.loginAuthenticationService.tabs)) {
              this.router.navigate([this.loginAuthenticationService.oldRedirectedUrl]).then(() => {
              });
            } else {
              this.eCaseSnackBarService.show('failure', ECaseUtils
                .getTranslatedValueFromKey(this.translate, 'ecase.common.redirectpagenoacessmessage')
                .replace('$redirectUrl', this.loginAuthenticationService.firstClickableFeature.url));
              this.router.navigate([this.loginAuthenticationService.firstClickableFeature.url]).then(() => {
              });
            }
          } else {
            this.router.navigate([this.loginAuthenticationService.firstClickableFeature.url]).then(() => {
            });
          }
          this.router.navigate([this.loginAuthenticationService.firstClickableFeature.url]).then(() => {
          });
        }
      } else {
        this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, _response.errorMessage));
      }
    });

  }

  exitAndLogOut(): void {
    this.loginAuthenticationService.logOut().subscribe(() => {
      this.router.navigate(['/']).then(() => {
      });
      this.titleService.setTitleWithSeparator(['ecase.common.defaultBrowserTabTitle']);
    });
  }
}
